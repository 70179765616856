<template>
  <PageHeader :title="title" />
    <div class="row g-4 mb-3">
      <div class="col-sm-auto">
          <div>
              <router-link to="/calls/other" ><b-button variant="outline-dark" class="waves-effect waves-light"><i class="ri-arrow-left-line align-bottom"></i> {{$t('back')}}</b-button></router-link>
          </div>
      </div>
      <div class="col-sm-auto">
        <button @click="showtask = !showtask" type="button" :class="`btn btn-${showtask == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${showtask == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
      </div>
      <div v-if="showtask == true" class="col-sm-auto">
        <div class="alert alert-info" style="padding: 0.5rem 1rem;margin-bottom: 0rem;">
            <p class="mb-0">
                <span class="fw-semibold">{{ $t('task') }} :</span>
                {{ this.$route.params.task }}
            </p>
        </div>
      </div>
    </div>

    <tablecustom
      :dataParams="dataParams"
      :objParams="objParams"
      @search="searchB" 
      :columns="columns" 
      :rows="rows"
      :pages="objPages"
      :showpag="true" 
      :createdform="true"
      @create="modalShow = true"  
      @open="open" 
      @getdata="getdata"
      @changelimit="changelimit"
    />

    <viewUnRegist
        v-if="showViewUnRegist == true"
        :objCard="this.objClient"
        @close = "comeback"
    ></viewUnRegist>
    
    <viewapplic 
      v-if="showModal == true" 
      @openUnregistered="openUnregistered" 
      @close="showModal = false" 
      @edit = 'edit' 
      :shortapplic="cardApplic" 
      :ifPrevopen="ifPrevopen" 
      :ifNextopen="ifNextopen" 
      @prevOpen="prevOpen" 
      @nextOpen="nextOpen"
    >
    </viewapplic>

    <!-- створити заявку -->
    <createapplicBox 
      v-if="modalShow" 
      @close="modalShow = false; this.perm = false"
      :id="this.$route.params.id" 
      @getdata="getdata"
      :editForm = "this.obj"
      :perm = "this.perm"
    />
    <!-- стоврити заявку -->
</template>
  
<script>
import viewUnRegist from '@/views/accounts/unregistered/view/index'
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import createapplicBox from './newrequest'
import { OtherCalls } from '@/API.js';
import viewapplic from '@/views/application/view/viewapplic.vue'
import { storeS } from "@/store";
  
let apiServe = new OtherCalls();
  
export default {
  // props: {cardApplic},
  components: {
    viewUnRegist,
    PageHeader,
    viewapplic,
    createapplicBox,
    tablecustom
  },
  data() {
    return {
      objClient: '',
      showViewUnRegist: false,
      obj: '',
      perm: false,
      showModal: false,
      title: this.$route.params.title,
      showtask: false,
      modalShow: false,
      objCard: '',
      ifNextopen: "",
      ifPrevopen: "",
      objPages: {},
      dataParams: {
        status: false,
        page: "calls"
      },
      objParams:{
        page: '1',
        pagelimit: '10',
        search: ''
      },
      columns: [
        {
          name: "ID",
          text: "requestId",
          align: "left",
          status: false,
        },
        {
          name: this.$t("phone_num"),
          text: "userPhone",
          align: "right",
          status: true,
        },
        {
          name: this.$t("date_create"),
          text: "createDatetime",
          align: "right",
          status: true,
        },
        {
          name: this.$t("added"),
          text: "createWorkerName",
          align: "right",
          status: true,
        },
      ],
      rows: [],
      lastModals: {}
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    changelimit(e) {
      
      this.objParams.pagelimit = e
      this.getdata()
    },
    comeback() {
      this.showViewUnRegist = false
      this.cardApplic = this.lastModals[0].form
      this.showModal = true
    },
    openUnregistered(client, modal) { 
      this.objClient = client
      this.lastModals = modal
      this.showViewUnRegist = true
    },
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiServe.getRequests(this.$route.params.id,this.objParams).then(result => {
          if(result.status == 'done'){
            this.rows = result.data.items;
            this.objPages = result.data;
          }
      })
    },
    open: function(e){
      var id = e.requestId ? e.requestId : e;
      this.ifPrevopen = id == this.rows[0].requestId ? false : true;
      this.ifNextopen = id == this.rows[this.rows.length-1].requestId ? false : true;

      apiServe.getRequest(id).then(result => {
        if(result.status == 'done'){
          this.cardApplic = result.data;
          this.showModal = true;
        }
      })
    },
    prevOpen(e){
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.rows){
        if(this.rows[value].requestId == current_id){
          prev_id = this.rows[Number(value)-Number('1')].requestId;
        }
      }
      this.open(prev_id);
    },
    nextOpen(e){
      var current_id = e;
      var next_id = "";

      for(var value in this.rows){
        if(this.rows[value].requestId == current_id){
          next_id = this.rows[Number(value)+Number('1')].requestId;
        }
      }
      this.open(next_id);
    },
    searchB(e){
      this.objParams.search = e
      this.getdata()
    },
    change(name, e){
      if(name == 'Application_type'){
        this.selectApplication_type = e;
      } else if(name == 'priority_type'){
        this.selectpriority = e;
      } else if(name == 'Manager'){
        this.selectuser = e;
      }
    },
    mutatestatus(e){
      var r = "";
      var cl = "";
      switch(e){
        case "new":
          r = this.$t('statusNew')
          cl = 'badge-soft-info'
          break;
        case "rescheduled":
          r = this.$t('statusrescheduled')
          cl = 'badge-soft-secondary'
          break;
        case "Executed":
          r = this.$t('Executed')
          cl = 'badge-soft-secondary'
          break;
        case "statuswait_delete":
          r = this.$t('statuswait_delete')
          cl = 'badge-soft-danger'
          break;
        case "wait_delete":
          r = this.$t('statuswait_delete')
          cl = 'badge-soft-danger'
          break;
      }
      return {r, cl};
    },
    edit(e) {
      if(e.type !== 'call') {
        this.obj = e
        this.perm = true
        this.modalShow = true
      }
    },
    mutaterequest(e){
      var r = "";
      switch(e){
        case "request":
          r = this.$t('Application')
          break;
        case "rescheduled":
          r = this.$t('statusrescheduled')
          break;
        case "wait_delete":
          r = this.$t('statuswait_delete')
          break;
      }
      return {r};
    },
    mutatepriority(e){
      var r = "";
      var cl = "";
      switch(e){
        case "high":
          r = this.$t('priorityHigh');
          cl = 'bg-danger'
          break;
        case "medium":
          r = this.$t('priorityMedium')
          cl = 'bg-warning'
          break;
        case "low":
          r = this.$t('priorityLow')
          cl = 'bg-success'
          break;
      }
      return {r, cl};
    },
    clearFilter(){
      this.selectApplication_type = "0";
      this.selectpriority = "0";
      this.selectuser = "0";
    }
  },
  computed: {
    perms() {
      return storeS.perms
    },
    user() {
      return storeS.userbase
    },
    routePath(){
      return this.$route.path 
      },
  },
  mounted() {
    this.eventBus.on('saveApplic', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  }
};
</script>
  
<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.howto_block {
  margin-left:10px;
}
.howto_block i {
  font-size: 23px;
  opacity: 0.5;
}
.howto_block i:hover{
  opacity: 1;
}

.howto_block {
  position: relative;
  display: inline-block;
}

.howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
}

.howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
